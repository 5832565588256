<template>
  <div ref="dom" class="charts chart-line"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object,
      loading:String,
      type: String
    },
    data() {
      return {
        dom: null,
        points: []
      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        let self = this;
        this.$nextTick(() => {
          let option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                animation: false
              }
            },
            grid:{
              top:70,
              left:70,
              bottom:50,
              right:120
            },
            toolbox: {
              right:120,
              top:10,
              feature: {
                dataZoom:{
                  yAxisIndex: "none"
                },
              }
            },
            legend: {
              show: true,
              right: 0,
              top: 40,
              itemGap: 20,
              itemWidth: 14,
              orient: "vertical",
              textStyle: { fontSize: 14, color: "#333333" },
            },
            xAxis: {
              name:val.xAxis.name,
              type: 'category',
              data: val.xAxis.data,
              nameLocation:'center',
              nameGap:25,
              axisLine:{
                onZero:false
              },
              splitLine:{
                show:false
              }
            },
            yAxis: {
              name:val.yAxis.name,
              type: 'value',
              nameLocation:'center',
              nameGap:40,
              scale: true,
              splitLine:{
                show:false
              }
            },
            series: [],
          };
          // 组装标记点
          let fftData = val.series[0].data;
          console.log("series", val)
          // 获取标记点
          let coordData = [];
          let markData = [];
          let border = self.getSigmaBorder(fftData);
          if (self.type === "H2") {
            self.points = [];
            self.getMarkPoint(fftData, border, 0, fftData.length);
            coordData = self.points;
            for (let i = 0; i < coordData.length; i++) {
              let coord = coordData[i];
              markData.push({
                coord: [val.xAxis.data[coord.index], coord.value-0],
                value: (val.xAxis.data[coord.index]-0).toFixed(3)
              })
            }
            border = self.getSigmaBorder(fftData);
            console.log("test===============",fftData, coordData, markData, border)
          }
          for (let item of val.series){
            option.series.push({
              name:item.name,
              type: "line",
              symbol:'none',
              data:item.data,
              markPoint: {
                data: markData
              },
            })
          }
          // fft检查
          option.series.push(
              {
                type:'line',
                name: "上界",
                symbol:'none',
                itemStyle: {
                  color: "#FF0000"
                },
                lineStyle:{
                  color: "#FF0000",
                },
                markLine: {
                  symbol:'none',
                  data: [
                    {
                      name: "border",
                      yAxis: border,
                      lineStyle:{
                        type:'solid',
                        color: "#FF0000",
                        cap:'butt'
                      },
                    }
                  ],
                  precision: 2
                }
              },
          )
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          console.log(option)
          this.dom.setOption(option);
          on(window, "resize", this.resize);
          this.$parent.changeLoad(this.loading)
        });
      },
      // 求上界
      getSigmaBorder(data) {
        let avg = this.getAvg(data);
        let sigma = this.getSigma(data,avg);
        return (avg + 3 * sigma).toFixed(4);
      },
      // 求数组标准差
      getSigma(data,avg) {
        let sigma = 0;
        for (let i = 0; i < data.length; i++) {
          let item = data[i] - 0;
          sigma += Math.pow(item-avg,2);
        }
        return Math.sqrt(sigma/data.length);
      },
      // 求平均值
      getAvg(data) {
        let val = 0;
        for (let i = 0; i < data.length; i++) {
          let item = data[i] - 0;
          val += item;
        }
        return val/data.length;
      },
      // 获取标记点
      getMarkPoint(data, border, left, right) {
        let length = right - left
        let step = 50;
        if(length < step * 2) return;
        let maxItem = data[left];let index = left;
        for (let i = left; i < right; i++) {
          if (data[i] > maxItem) {
            maxItem = data[i];
            index = i;
          }
        }
        if(index === left || index === right) return;
        if(border > maxItem || this.getSigmaBorder(data.slice(index - step, index + step)) > maxItem) return;
        this.points.push({index: index, value: maxItem});
        if(index - step > left) this.getMarkPoint(data, border, left, index - step);
        if(right > index + step) this.getMarkPoint(data, border, index + step, right)
      }
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: 100%;
  }
</style>
