<template>
  <div class="con manage-scroll">
    <div class="sta-content" v-loading="loading">
      <div class="sta-one" v-loading="loading1" v-if="sensorName.indexOf('D1') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :type-show="true" :direction-show="true" :range-show="true" :title-text="chartData1.title" @changeForm="getData1"></chart-title>
        <chart-one :value="chartData1" v-loading="loading1"></chart-one>
      </div>
      <div class="sta-one" v-loading="loading2" v-if="sensorName.indexOf('E1') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :sensor-show="true" :sensor-mul="true" :range-show="true" :title-text="chartData2.title" @changeForm="getData2"></chart-title>
        <chart-two ref="chartFour" :value="chartData2"></chart-two>
      </div>
      <div class="sta-one" v-loading="loading3" v-if="sensorName.indexOf('G1') !== -1">
        <chart-three :value="chartData3"></chart-three>
      </div>
      <div class="sta-one" v-loading="loading4" v-if="sensorName.indexOf('J1') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :sensor-show="true" :range-show="true" :title-text="chartData4.title" @changeForm="getData4"></chart-title>
        <chart-four :value="chartData4"></chart-four>
      </div>
      <div class="sta-one" v-loading="loading5" v-if="sensorName.indexOf('J2') !== -1">
        <chart-title :currentSensorList="currentSensorList" :sensor-detai-list="sensorDetaiList" :sensor-show="true" :sensor-mul="true" :range-show="true" :type-show="true" :title-text="chartData5.title" @changeForm="getData5"></chart-title>
        <chart-five :value="chartData5" loading="loading5"></chart-five>
      </div>
      <div class="sta-one" v-loading="loading6" v-if="sensorName.indexOf('F1') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :bridge-show="true" :range-show="true" date-type="time" :title-text="chartData6.title" @changeForm="getData6"></chart-title>
        <chart-six :value="chartData6"></chart-six>
      </div>
      <div class="sta-one" v-loading="loading7" v-if="sensorName.indexOf('F2') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :sensor-show="true" :range-show="true" date-type="time" :title-text="chartData7.title" @changeForm="getData7"></chart-title>
        <chart-seven :value="chartData7" loading="loading7"></chart-seven>
      </div>
      <div class="sta-one" v-loading="loading8" v-if="sensorName.indexOf('F3') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :sensor-show="true" :range-show="true" date-type="time" :title-text="chartData8.title" @changeForm="getData8"></chart-title>
        <chart-seven :value="chartData8" loading="loading8"></chart-seven>
      </div>
      <div class="sta-one" v-loading="loading9" v-if="sensorName.indexOf('I1') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :type-show="true" :direction-show="true" :time-show="true" :title-text="chartData9.title" @changeForm="getData9"></chart-title>
        <chart-eight :value="chartData9"></chart-eight>
      </div>
      <div class="sta-one" v-loading="loading10" v-if="sensorName.indexOf('I2') !== -1">
        <chart-title :currentSensorList="currentSensorList" :sensor-detai-list="sensorDetaiList" :sensor-show="true" :sensor-mul="true" :range-show="true" :title-text="chartData10.title" @changeForm="getData10"></chart-title>
        <chart-five :value="chartData10" loading="loading10"></chart-five>
      </div>
      <div class="sta-one" v-loading="loading11" v-if="sensorName.indexOf('H1') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :sensor-show="true" :range-show="true" date-type="time" :title-text="chartData11.title" @changeForm="getData11"></chart-title>
        <chart-seven :value="chartData11" loading="loading11"></chart-seven>
      </div>
      <div class="sta-one" v-loading="loading12" v-if="sensorName.indexOf('H2') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :sensor-show="true" :range-show="true" date-type="time"  :title-text="chartData12.title" @changeForm="getData12"></chart-title>
        <chart-seven :value="chartData12" loading="loading12" type="H2"></chart-seven>
      </div>
      <!--      <div class="sta-one" v-loading="loading13" v-if="sensorName.indexOf('I3') !== -1">-->
      <!--        <chart-title :title-text="chartData13.title"></chart-title>-->
      <!--        <chart3-d ref="chart3D" :value="chartData13" loading="loading13" @getData="getData13"></chart3-d>-->
      <!--      </div>-->
      <div class="sta-one" style="height: 500px" v-loading="loading14" v-if="sensorName.indexOf('L1') !== -1">
        <chart-title :gnss-type-list="gnssTypeList" :gnss-show="true" :range-show="true"  :title-text="chartData14.title" @changeForm="getData14"></chart-title>
        <div class="sta-gnss">
          <div class="sta-gnss-flex" v-for="(item,i) in chartData14.list" :key="i">
            <chart-nine :value="item"></chart-nine>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chartOne from "../charts/chart01.vue";
import chartTwo from "../charts/chart02.vue";
import chartThree from "../charts/chart03.vue";
import chartFour from "../../environment/charts/chart03.vue";
import chartFive from "../charts/chart04.vue";
import chartSix from "../charts/chart05.vue";
import chartSeven from "../charts/chart06.vue";
import chartEight from "../charts/chart07.vue";
import chartNine from "../charts/chart09.vue";
import chart3D from "../charts/chart3D.vue"
import chartTitle from "../../common/components/chartTitle";
export default {
  components: {
    chartOne,chartTwo,chartThree,chartFour,chartFive,chartSix,chartSeven,chartEight,chart3D,chartTitle,chartNine
  },
  data() {
    return {
      loading:false,
      loading1:false,
      loading2:false,
      loading3:false,
      loading4:false,
      loading5:false,
      loading6:false,
      loading7:false,
      loading8:false,
      loading9:false,
      loading10:false,
      loading11:false,
      loading12:false,
      loading13:false,
      loading14:false,
      chartData1: {
        title:'主梁挠度',
        xAxis: {name:'', data:[]},
        yAxis: {name:'挠度/m'},
        series:[]
      },
      chartData2: {
        title:'梁端及支座位移',
        xAxis: {name:'日期', data:[]},
        yAxis: {name:'单日累计位移(mm)'},
        series:[]
      },
      chartData3:{
        title:'偏位/转角',
        data:[[0.03,0.03,0.03],[0.02,0.02,0.02], [0.04,0.04,0.04], [0.01,0.01,0.01], [-0.02,-0.02,-0.02], [-0.03,-0.03,-0.03], [-0.01,-0.01,-0.01], [-0.04,-0.04,-0.04], [0.05,0.05,0.05], [0.02,0.03,0.03], [0.04,0.05,0.02],[1,1,1],[-1,-1,-1],[1,1,0]]
      },
      chartData4: {
        title:'应力增量分布图',
        xAxis: {name:'应力(MPa)', data:[]},
        yAxis: {name:''},
        data:[]
      },
      chartData5: {
        title:'应力测点最值图',
        xAxis:{name:'应力测点', data:[]},
        yAxis:{name:'应力(Mpa)'},
        series:[]
      },
      chartData6: {
        title:'加速度包络图',
        xAxis: {name:'测点号', data:[]},
        yAxis: {name:'加速度/mg'},
        series:[]
      },
      currentSensorList:[],
      chartData7:{
        title:'震动加速度时域图',
        xAxis:{name:'', data:[]},
        yAxis:{name:''},
        series:[]
      },
      chartData8:{
        title:'震动加速度频域图',
        xAxis:{name:'', data:[]},
        yAxis:{name:''},
        series:[{name:'', data:[]}]
      },
      chartData9:{
        title:'全桥索力图',
        xAxis:{name:'', data:[]},
        yAxis:{name:'',},
        series:[]
      },
      chartData10: {
        title:'索力增量最值图',
        xAxis:{name:'索力测点', data:[]},
        yAxis:{name:'ICP(KN)',},
        series:[]
      },
      chartData11:{
        title:'索力时域图',
        xAxis:{name:'', data:[]},
        yAxis:{name:''},
        series:[{name:'', data:[]}]
      },
      chartData12:{
        title:'索力频域图',
        xAxis:{name:'', data:[]},
        yAxis:{name:''},
        series:[{name:'', data:[]}]
      },
      chartData13:{
        title:'桥梁模型',
        data:{}
      },
      chartData14:{
        title:'数据统计',
        list:[]
      },
      sensorDetaiList:[],
      sensorName:'',
      gnssTypeList:[]
    };
  },
  methods:{
    changeSensorDetail(data,name){
      // this.sensorName = name
      if (data.length > 0){
        this.sensorDetaiList = data;
        this.currentSensorList = []
        this.sensorDetaiList.forEach((item,i) => {
          if (i < 10){
            this.currentSensorList.push(item.id)
          }
        })
        this.sensorName = this.sensorDetaiList[0].chart
      }
      if (name.includes('GNSS')){
        this.getGnssType()
      }
      // if (this.sensorName.indexOf('索') !== -1){
      //   this.getData13()
      // }
    },
    getGnssType(){
      this.$http.get('/statistics/response/gnssTypes').then(res => {
        if (res.success){
          this.gnssTypeList = res.data ? res.data : []
        }
      })
    },
    //挠度
    getData1(data){
      let params = {
        direction:data.direction,
        type:data.type,
      }
      if (data.time.length > 0){
        params.startTime = data.time[0]
        params.endTime = data.time[1]
      }
      if (data.direction){
        this.loading = true;
        this.$http.post("/statistics/response/deflection/lineNew",params).then(res=>{
          if(res.success){
            this.chartData1.xAxis.data = res.data.xaxis
            this.chartData1.series = res.data.dataList
          }
          this.loading = false;
        })
      }
    },
    //位移
    getData2(data){
      let params = {
        sensorIdList:data.sensorIdList,
      }
      if (data.time.length > 0){
        if (data.time[0].length < 12){
          data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
        }
        params.startTime = data.time[0]
        params.endTime = data.time[1]
      }
      if (data.sensorIdList && data.time.length > 0){
        // this.loading2 = true
        this.loading = true;
        this.$http.post("/statistics/response/displacement/cumulative",params).then(res=>{
          if(res.success){
            this.chartData2.series = []
            this.chartData2.xAxis.data = res.data.xaxis
            if (res.data.dataList){
              for (let i = 0 ; i < res.data.dataList.length; i++){
                this.chartData2.series.push({name:res.data.dataList[i].type,data:res.data.dataList[i].yaxis})
              }
            }
            this.$refs.chartFour.loadEcharts(this.chartData2);
          } else {
            this.loading2 = false
          }
          this.loading = false;
        })
      }
    },
    // 应力增量
    getData4(data){
      // chartData4
      let params = {
        sensorId:data.sensorId,
      }
      if (data.time.length > 0){
        if (data.time[0].length < 12){
          data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
        }
        params.startTime = data.time[0]
        params.endTime = data.time[1]
      }
      if (data.sensorId && data.time.length > 0){
        // this.loading4 = true
        this.loading = true;
        this.$http.post("/statistics/response/stressMeter/addBar",params).then(res=>{
          if(res.success){
            this.chartData4.xAxis.data = res.data.xaxis
            this.chartData4.data = res.data.yaxis
            this.chartData4.yAxis.name = res.data.yunit
          } else {
            this.loading4 = false
          }
          this.loading = false;
        })
      }
    },
    // 应力最值
    getData5(data){
      // chartData5
      let params = {
        type:data.type,
        pointList:data.sensorIdList,
      }
      if (data.time.length > 0){
        if (data.time[0].length < 12){
          data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
        }
        params.startTime = data.time[0]
        params.endTime = data.time[1]
      }
      if (data.type && data.sensorIdList && data.time.length > 0){
        // this.loading5 = true
        this.loading = true;
        this.$http.post("/statistics/response/stressMeter/valueLine",params).then(res=>{
          if(res.success){
            this.chartData5.xAxis.data = res.data.xaxis
            this.chartData5.series = []
            this.chartData5.series.push({
              name:res.data.maxData.type,
              data:res.data.maxData.yaxis
            })
            this.chartData5.series.push({
              name:res.data.minData.type,
              data:res.data.minData.yaxis
            })
          } else {
            this.loading5 = false
          }
          this.loading = false;
        })
      }
    },
    //加速度包络图
    getData6(data){
      let params = {
        type:data.bridge,
      }
      if (data.time.length > 0){
        if (data.time[0].length < 12){
          data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
        }
        params.startTime = data.time[0]
        params.endTime = data.time[1]
      }
      if (data.bridge && data.time.length > 0){
        // this.loading6 = true
        this.loading = true;
        this.$http.post("/statistics/response/acceleration/envelopeLine",params).then(res=>{
          if(res.success){
            this.chartData6.xAxis.data = res.data.xdata
            this.chartData6.series = res.data.series
          } else {
            this.loading6 = false
          }
          this.loading = false;
        })
      }
    },
    //振动时域
    getData7(data){
      let params = {
        sensorId:data.sensorId,
      }
      if (data.time.length > 0){
        if (data.time[0].length < 12){
          data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
        }
        params.startTime = data.time[0]
        params.endTime = data.time[1]
      }
      if (data.sensorId && data.time.length > 0){
        // this.loading7 = true
        this.loading = true;
        this.$http.post("/statistics/response/acceleration/timeLine",params).then(res=>{
          if(res.success){
            this.chartData7.xAxis.data = res.data.xdata
            this.chartData7.yAxis.name = res.data.unit
            this.chartData7.series = res.data.series
          } else {
            this.loading7 = false
          }
          this.loading = false;
        })
      }
    },
    //振动频域
    getData8(data){
      let params = {
        sensorId:data.sensorId,
      }
      if (data.time.length > 0){
        if (data.time[0].length < 12){
          data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
        }
        params.startTime = data.time[0]
        params.endTime = data.time[1]
      }
      if (data.sensorId && data.time.length > 0){
        // this.loading8 = true
        this.loading = true;
        this.$http.post("/statistics/response/acceleration/frequencyLine",params).then(res=>{
          if(res.success){
            this.chartData8.xAxis.data = res.data.xaxis
            this.chartData8.yAxis.name = res.data.yunit
            this.chartData8.series[0].data = res.data.yaxis
          } else {
            this.loading8 = false
          }
          this.loading = false;
        })
      }
    },
    // 索力结算包络图
    getData9(data){
      let params = {
        type:data.type,
        dataTime:data.dateTime,
        itemId:data.direction
      }
      if (data.type && data.dateTime && data.direction){
        // this.loading9 = true
        this.loading = true;
        this.$http.post("/statistics/response/cableForce/resolve/mix",params).then(res=>{
          if(res.success){
            this.chartData9.xAxis.data = res.data.xaxis
            this.chartData9.yAxis.name = res.data.yunit
            this.chartData9.series = []
            if (res.data.cableForceResolveDataList.length > 0){
              res.data.cableForceResolveDataList.forEach(bar => {
                this.chartData9.series.push({
                  name:bar.type,
                  type:'bar',
                  data:bar.yaxis
                })
              })
            }
            if (res.data.limitData && res.data.limitData.length > 0){
              res.data.limitData.forEach(line => {
                this.chartData9.series.push({
                  name:line.type,
                  type:'line',
                  data:line.yaxis
                })
              })
            }
          } else {
            this.loading9 = false
          }
          this.loading = false;
        })
      }
    },
    //索力解算最值
    getData10(data){
      let params = {
        pointList:data.sensorIdList,
      }
      if (data.time.length > 0){
        if (data.time[0].length < 12){
          data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
        }
        params.startTime = data.time[0]
        params.endTime = data.time[1]
      }
      if (data.sensorIdList && data.time.length > 0){
        // this.loading10 = true
        this.loading = true;
        this.$http.post("/statistics/response/cableForce/Most",params).then(res=>{
          if(res.success){
            this.chartData10.xAxis.data = res.data.xaxis
            this.chartData10.series = []
            this.chartData10.series.push({
              name:res.data.maxData.type,
              data:res.data.maxData.yaxis
            })
            this.chartData10.series.push({
              name:res.data.minData.type,
              data:res.data.minData.yaxis
            })
          } else {
            this.loading10 = false
          }
          this.loading = false;
        })
      }
    },
    //索力时域
    getData11(data){
      let params = {
        pointId:data.sensorId,
      }
      if (data.time.length > 0){
        if (data.time[0].length < 12){
          data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
        }
        params.startTime = data.time[0]
        params.endTime = data.time[1]
      }
      if (data.sensorId && data.time.length > 0){
        // this.loading11 = true
        this.loading = true;
        this.$http.post("/statistics/response/cableForce/line",params).then(res=>{
          if(res.success){
            this.chartData11.xAxis.data = res.data.xaxis
            this.chartData11.yAxis.name = res.data.yunit
            this.chartData11.series[0].data = res.data.yaxis
            this.loading11 = false
          } else {
            this.loading11 = false
          }
          this.loading = false;
        })
      }
    },
    //索力频域
    getData12(data){
      let params = {
        pointId:data.sensorId,
      }
      if (data.time.length > 0){
        if (data.time[0].length < 12){
          data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
        }
        params.startTime = data.time[0]
        params.endTime = data.time[1]
      }
      if (data.sensorId && data.time.length > 0){
        // this.loading12 = true
        this.loading = true;
        this.$http.post("/statistics/response/cableForce/fLine",params).then(res=>{
          if(res.success){
            let data = res.data.yaxis;
            console.log("data=========",data)
            for (let i = 0; i < 20; i++) {
              data[i] = 0;
            }
            this.chartData12.xAxis.data = res.data.xaxis.slice(0, res.data.xaxis.length * 0.3);
            this.chartData12.yAxis.name = res.data.yunit
            this.chartData12.series[0].data = data.slice(0, data.length * 0.3);
          } else {
            this.loading12 = false
          }
          this.loading = false;
        })
      }
    },
    //桥模型数据
    getData13(){
      this.loading13 = true
      this.$http.get("/echart/data/wholeBridge").then(res => {
        if(res.success){
          if (res.data){
            this.$refs['chart3D'].loadEcharts(res.data)
          }
        } else {
          this.$message.error(res.msg)
        }
        this.loading13 = false
      })
    },
    getData14(data){
      console.log("gnssData",data);
      this.loading14 = true
      let params = {
        id:data.gnssId,
        startTime:data.time[0],
        endTime:data.time[1]
      }
      this.$http.post('/statistics/response/gnss/scatter',params).then(res => {
        if (res.success){
          this.chartData14.list = res.data.scatter
        }
        this.loading14 = false
      })
    },
    changeLoad(key){
      this[key] = false
    },
    changeCommonLoad3(){
      this.loading4 = false
    }
  }
};
</script>

<style scoped>
.manage-scroll{
  height: calc(100% - 400px);
}
.sta-title{
  height: 60px;
  padding: 0 20px;
  background: #fff;
  margin-bottom: 10px;
}
.title-text{
  font-size: 16px;
  color: #1A67D0;
  font-weight: 900;
}
.sta-content {
  /* height: 400px; */
  height: 100%;
  background-color: #fff;
}
.sta-one{
  width: 100%;
  height: 400px;
  /* height: 100%; */
  background: #fff;
  margin-bottom: 10px;
  padding: 20px;
}
.sta-two{
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.sta-con{
  width: 49.7%;
  height: 100%;
  padding: 20px;
  background: #fff;
}
/deep/ .el-form-item{
  margin-bottom: 0;
}
/deep/ .el-date-editor .el-range-separator{
  padding: 0;
}

.sta-gnss{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.sta-gnss-flex{
  width: 33%;
  height: 100%;
}
</style>
